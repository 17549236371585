<template>
  <div class="basic-loading">
    <img
      src="../assets/loading.svg"
      alt=""
      style="height:50px"
   
      :width="getLoadingIconSize"
    />
    <span class="mt-4" v-if="tip" >{{ tip }}</span>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    getLoadingIconSize: "50px",
    getLoadingIconSize: "50px",
    tip:{
        type: String ,
        default: '',
      },
  
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
  .basic-loading {
      position: absolute; 
      z-index: 10;
    display: flex;
    width: 90%;
    height: 100%;
    // background: rgba(255, 255, 255, 0.3);
    background: rgba(241, 241, 246, 0.7);
    justify-content: center;
    align-items: center;
  }
</style>