<template>
  <div>
    <!-- {{username}}
     {{password}} -->
  </div>
</template>

<script>

import Qs from "qs";
import { resetRouter, filterAsyncRouter } from "@/router/index";
import { AsyncLog } from "../../api/oa"
import router from "@/router";
import jwtdecode from "jwt-decode"
import jwt from 'jsonwebtoken'
import axios from "axios";

export default {
  name: "loginSingle",
data() {
    return {
      token: this.$route.query.token,
      refresh_token: this.$route.query.refresh_token,  
      page: this.$route.query.page
    };
  },
  async created () {
   this.login();
//     console.log(this.$route.prams);
//  console.log(this.$route.query);
  },
 methods: {
    async login() {
      // alert();
      let self=this;
      var obj = jwtdecode(self.token);
      // this.$store.commit("login", obj);
      // alert(self.page);
      window.localStorage.setItem("page",self.page);
      // alert(window.localStorage.getItem("page"));
      this.$store.commit("loginSingle", self.token,self.refresh_token);
      // console.log(obj);

      // this.$store.commit("user", JSON.stringify(jwtdecode(self.access_token)));
      // console.log(JSON.stringify(jwt.decode(self.access_token)))
      // window.localStorage.setItem("user", obj);
      // this.$store.commit("user", obj);
      // var data = {
      //   grant_type: "password",
      //   username: this.$route.query.username,
      //   password: this.$route.query.password,
      //   client_id: "client-gzk-pwd",
      //   client_secret:"123456"
      // };
      // //获取token
      // var user = await this.$api.getUserToken(Qs.stringify(data));
      // console.log(user);
      // if (user.status == 200) {
      //   this.$store.commit("login", user.data);
      // } else {
      //   this.islogin = false;
      //   this.$message({
      //     message: "用户名或密码错误",
      //     type: "error",
      //   });
      //   return false;
      // }


      const params = {};
      params.grant_type = 'refresh_token';
      params.client_id = 'client-grad-pwd';
      params.client_secret = 'T78JVybD7u2kBcss';
      // params.refresh_token = store.state.refresh_token;
      params.refresh_token = this.$route.query.refresh_token;
      await axios.post(`https://api.gradgroup.cn/oauth/token`,
        Qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(res => {
        // console.log(res.data)
        this.$store.commit("login", res.data);
      })
      .catch(res => {
        // console.log('catch')
        console.error('refreshtoken error =>', res)
        this.$store.commit('logout');          
        this.$router.push({ path: '/Login' });
      }).finally(() => {
        
      });
      
      //获取菜单
      var _this = this;
      var user = JSON.parse(window.localStorage.user);
      //写登录日志
      AsyncLog({userId:user.sub,type:2}).then((res) => {
        // console.log(res);
      })

      var loginParams = { uid: obj.sub, appId: global.appId };
      var nav = await this.$api.GetModuleNavigationBar(loginParams);
      // console.log(nav);
      if (!nav.success) {
        // _this.$message({
        //   message: nav.msg,
        //   type: "warning",
        // });
         _this.$message({
          message: '因较长时间未操作，验证信息或已过期，请刷新页面重新登录',
          type: "warning",
        });
        setTimeout(() => {
          this.$router.replace({
            path: "/login",
          });
        }, 5000);
        return false;
      } else {
        window.localStorage.menu = JSON.stringify(nav.response.children);

        let getRouter = nav.response.children; //后台拿到路由
        getRouter = filterAsyncRouter(getRouter); //过滤路由
        router.$addRoutes(getRouter); //动态添加路由


              if(self.page==undefined)
              {
                
              }else{
                if(self.page=='' || self.page=='undefined')
                {
                  
                }
                else if(self.page=='waitdo')
                {
                  self.getNeButtonList(obj.sub, getRouter[1].id);
                  setTimeout(() => {
                    this.$router.replace({
                      path: "/Task/WaitDoTask",
                    });
                    window.localStorage.removeItem("page");
                  }, 1500);
                }
                else if(self.page=='waitread')
                {
                  this.getNeButtonList(obj.sub, getRouter[1].id);
                  setTimeout(() => {
                    this.$router.replace({
                      path: "/Task/WaitReadTask",
                    });
                    window.localStorage.removeItem("page");
                  }, 1000);
                }
                else if(self.page=='waitapproval')
                {
                  this.getNeButtonList(obj.sub, getRouter[1].id);
                  setTimeout(() => {
                    this.$router.replace({
                      path: "/Task/WaitApproval",
                    });
                    window.localStorage.removeItem("page");
                  }, 1000);
                }
              }





        if (getRouter[0]["type"] == 0) {
          //单页面引用
          this.$router.replace(getRouter[0]["path"]).catch((data) => {});
        } else if (getRouter[0]["type"] == 1) {
          //带导航应用

          this.getNeButtonList(auid, getRouter[0]["id"]);
        } else if (getRouter[0]["type"] == 2) {
          this.$router.push({
            name: "userTemplate",
            query: {
              reportUrl: getRouter[0]["path"],
              reportType: 0,
              t: Date.now(),
            },
          });
        }
      }
      this.$router.replace({
        path: "/",
      });
      this.$message.success("登录成功，欢迎进入工作任务系统");
      this.islogin = false;
    },
    //获取按钮
    async getNeButtonList(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNeButtonList(loginParams).then((data) => {

        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          window.localStorage.buttList = JSON.stringify(data.response);
          this.GetNavigationBarByChilder(uid, pid);
        }
      });
    },
    async GetNavigationBarByChilder(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNewMenuNavigationBar(loginParams).then((data) => {
        // console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          let getRouter = data.response.children; //后台拿到路由

          window.localStorage.router = JSON.stringify(data.response.children);
          if (getRouter.length > 0) {
            this.isCollapsdMenu = true;
            this.routes = getRouter;

            getRouter = filterAsyncRouter(getRouter); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由

            // if (
            //   getRouter[0]["children"] != null &&
            //   getRouter[0]["children"].length > 0
            // ) {
            //   let cPath = this.hasChilden(getRouter[0]["children"][0]);

            //   _this.$router.replace(cPath).catch((data) => {});
            // } else {
            //   _this.$router.replace(getRouter[0]["path"]).catch((data) => {});
            // }
          }
        }
      });
    },
    hasChilden(res) {
      // console.log(res)
      if (res["children"] != null && res["children"].length > 0) {
        this.hasChilden(res["children"][0]);
      } else {
        // console.log(res)
        return res["path"];
      }
    },
  },
};
</script>

<style>
</style>
