<template>
  <div>
    <div desktop="12" tablet="8">
      <dl>
        <dt>Login successful</dt>
        <dt>Your browser should be redirected soon</dt>
      </dl>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "IdentityServerRefreshToken",
  data () {
    return {};
  },
  async created () {
    // try {
  
     
    //   let _this = this;
      
    //   applicationUserManager.signinSilentCallback().then(function () {
    //         applicationUserManager.getUser().then(function(user) {
       
    //        _this.$store.commit("saveToken", user.access_token);

    //   var curTime = new Date();
    //   var expiredate = new Date(
    //     curTime.setSeconds(curTime.getSeconds() + user.expires_in)
    //   );
    //   _this.$store.commit("saveTokenExpire", expiredate);

    //   window.localStorage.refreshtime = expiredate;
    //   window.localStorage.expires_in = user.expires_in;
    //   user.uRealName = user.profile.name;
    //   user.uLoginName = user.profile.preferred_username;
    //   user.sub = user.profile.sub;
    //    console.log(user);
    //   window.localStorage.user = JSON.stringify(user);
    
    //         });
          
    //  }).catch(function (e) {
    //        console.log(e);
    //   });

    // } catch (e) {
    //   this.$root.$emit("show-snackbar", { message: e });
    // }
  },
  methods: {
    
  }
};
</script>

<style>
</style>
