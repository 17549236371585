import axios from "./api";
  //获取左侧导航菜单
  export const GetModuleNavigationBar = params => {
    return axios.get(`/wt/Module/GetNavigationByApplicationIdAndUserId`, { params: params }).then(res => res.data);;
  };
    //获取导航菜单
  export const GetNewMenuNavigationBar = params => {
    return axios.get(`/wt/Menu/GetNavigationByModuleIdAndUserId`, { params: params }).then(res => res.data);;
  };
  //获取按钮组
  export const GetNeButtonList = params => {
    return axios.get(`/wt/Button/GetButtonAllByModuleIdAndUserId`, { params: params }).then(res => res.data);
  };
  //用户修改密码
  export const UserModifytPassword = params => {
    return axios.put(`/uc/api/User/ModifyUserPassword`, params);
};
//获取用户token
export function getUserToken(params) {
    // return axios.post(`https://sts.gradgroup.cn/connect/token`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    return axios.post(`https://api.gradgroup.cn/oauth/token`, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } });
    // return axios.post(`/auth/oauth/token`, params,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
}