<template>
  <div class="login_bg">
    <div class="login_container">
      <div class="login_body">
        <div class="login-form">
          <div class="login-logo">
            <!--<img class="logo" src="https://www.gradgroup.cn/iportal/images/logo.png" />-->
            <h2>格瑞德工作任务系统</h2>
            <h3 style="color:#777;letter-spacing:1px; " >GRAD Work Task System</h3>
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0"
            size="large"
          >
            <el-form-item prop="user">
              <el-input
                v-model="ruleForm.user"
                prefix-icon="el-icon-user"
                clearable
                placeholder="请输入用户名"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="ruleForm.password"
                prefix-icon="el-icon-lock"
                clearable
                show-password
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                style="width: 100%"
                :loading="islogin"
                round
                @click="login"
                >登 录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="login-sidebox">
          <img :src=imageSrc />
        </div>
      </div>
      <div class="login-footer">©2023 山东格瑞德集团有限公司版权所有</div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import { resetRouter, filterAsyncRouter } from "@/router/index";
import router from "@/router";
import Images from '@/assets/images/dlbox.svg'
import {AsyncLog} from "../api/oa"
export default {
  data() {
        return {
            imageSrc: Images,
      userType: "admin",
      ruleForm: {
        user: "",
        password: "",
        autologin: false,
      },
      rules: {
        user: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      islogin: false,
    };
  },

  methods: {
    async login() {
      this.islogin = true;
      //   var data = {
      //     grant_type: "password",
      //     client_id: "client-gzk-pwd",
      //     client_secret: "123456",
      //     username: this.ruleForm.user,
      //     password: this.ruleForm.password,
      //   };
      var data = {
        grant_type: "password",
        username: this.ruleForm.user,
        password: this.ruleForm.password,
        client_id: "client-grad-pwd",
        client_secret:"T78JVybD7u2kBcss"
      };
      try {
        var user = await this.$api.getUserToken(Qs.stringify(data));
        if (user.status == 200) {
          // console.log(user);
          this.$store.commit("login", user.data);
        } else {
          this.islogin = false;
          var msg = user.data.msg;
          if(msg)
          {
            msg = user.data.msg;
          }else{
            msg = '用户名或密码不正确';
          }
          this.$message({
            message: msg,
            type: "warning",
          });
          this.islogin = false;
          return false;
        }
      } catch (error) {
        this.$message({
          message: '用户名或密码不正确',
          type: "warning",
        });
        this.islogin = false;
        return false;
      }

      //获取菜单
      var _this = this;
      var user = JSON.parse(window.localStorage.user);
      // console.log(user);

      //写登录日志
      AsyncLog({userId:user.sub,type:1}).then((res) => {
        // console.log(res);
      })
      // console.log(user);
      var loginParams = { uid: user.sub, appId: global.appId };
      var nav = await this.$api.GetModuleNavigationBar(loginParams);
      // console.log(nav);
      if (!nav.success) {
        _this.$message({
          message: nav.msg,
          type: "error",
        });
        return false;
      } else {
        window.localStorage.menu = JSON.stringify(nav.response.children);

        let getRouter = nav.response.children; //后台拿到路由
        getRouter = filterAsyncRouter(getRouter); //过滤路由
        router.$addRoutes(getRouter); //动态添加路由

        if (getRouter[0]["type"] == 0) {
          //单页面引用
          this.$router.replace(getRouter[0]["path"]).catch((data) => {});
        } else if (getRouter[0]["type"] == 1) {
          //带导航应用

          this.getNeButtonList(auid, getRouter[0]["id"]);
        } else if (getRouter[0]["type"] == 2) {
          this.$router.push({
            name: "userTemplate",
            query: {
              reportUrl: getRouter[0]["path"],
              reportType: 0,
              t: Date.now(),
            },
          });
        }
      }
      this.$router.replace({
        path: "/",
      });
      this.$message.success("登录成功，欢迎进入工作任务系统");
      this.islogin = false;
    },
    //获取按钮
    async getNeButtonList(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNeButtonList(loginParams).then((data) => {
        // console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          window.localStorage.buttList = JSON.stringify(data.response);
          this.GetNavigationBarByChilder(uid, pid);
        }
      });
    },
    async GetNavigationBarByChilder(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      await this.$api.GetNewMenuNavigationBar(loginParams).then((data) => {
        // console.log(data)
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          let getRouter = data.response.children; //后台拿到路由

          window.localStorage.router = JSON.stringify(data.response.children);
          if (getRouter.length > 0) {
            this.isCollapsdMenu = true;
            this.routes = getRouter;

            getRouter = filterAsyncRouter(getRouter); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由

            if (
              getRouter[0]["children"] != null &&
              getRouter[0]["children"].length > 0
            ) {
              let cPath = this.hasChilden(getRouter[0]["children"][0]);

              _this.$router.replace(cPath).catch((data) => {});
            } else {
              _this.$router.replace(getRouter[0]["path"]).catch((data) => {});
            }
          }
        }
      });
    },
    hasChilden(res) {
      // console.log(res)
      if (res["children"] != null && res["children"].length > 0) {
        this.hasChilden(res["children"][0]);
      } else {
        // console.log(res)
        return res["path"];
      }
    },
  },
};
</script>

<style scoped>
.login_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.login_container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1100px;
  margin: 0 auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.login_body {
  width: inherit;
  display: flex;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.3);
}
.login-sidebox {
  width: 50%;
  padding: 60px;
  color: #fff;
  background: var(--el-color-primary);
  position: relative;
  overflow: hidden;
}
.login-sidebox__title h2 {
  font-size: 30px;
}
.login-sidebox__title h4 {
  font-size: 18px;
  margin-top: 10px;
  font-weight: normal;
}
.login-sidebox__title p {
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.6);
}
.login-sidebox img {
  /*position: absolute;*/
  left: -120px;
  bottom: -160px;
  width: 440px;
}

.login-logo {
  text-align: center;
  margin-bottom: 30px;
  cursor: default;
}
.login-logo .logo {
  height: 70px;
  vertical-align: bottom;
}
.login-logo h2 {
  font-size: 24px;
  margin-top: 20px;
  color: #40485b;
}

.login-title {
  margin-top: 20px;
}
.login-title h2 {
  font-size: 22px;
  font-weight: normal;
}
.login-title p {
  font-size: 12px;
  margin-top: 40px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.login-form {
  width: 50%;
  padding: 60px 100px;
  background: #fff;
}
.login-oauth {
  display: flex;
  justify-content: space-around;
}
.login-form .el-divider {
  margin-top: 40px;
}

.login-footer {
  text-align: center;
  color: #999;
  margin-top: 50px;
}

.demo-user-item {
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 10px 0;
}
.demo-user-item .icon {
  margin-right: 20px;
}
.demo-user-item .info h2 {
  font-size: 14px;
}
.demo-user-item .info p {
  color: #666;
  margin-top: 6px;
}

@media (max-height: 650px) {
  .login_bg {
    position: static;
  }
  .login_container {
    position: static;
    transform: none;
    margin: 50px auto;
  }
  .login-footer {
    margin-bottom: 50px;
  }
}
@media (max-width: 1200px) {
  .login_container {
    width: 900px;
  }
  .login-form {
    padding: 60px;
  }
}
@media (max-width: 1000px) {
  .login_container {
    width: 100%;
    background: #fff;
    margin: 0;
    transform: none;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .login_body {
    box-shadow: none;
  }
  .login-form {
    width: 100%;
    padding: 60px 40px;
  }
  .login-sidebox {
    display: none;
  }
  .login-footer {
    margin-top: 0;
  }
}
</style>
