var SIGN_REGEXP = /([yMdhsm])(\1*)/g;
var DEFAULT_PATTERN = 'yyyy-MM-dd';
function padding(s, len) {
    var len = len - (s + '').length;
    for (var i = 0; i < len; i++) { s = '0' + s; }
    return s;
};

export default {
    getQueryStringByName: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        var context = "";
        if (r != null)
            context = r[2];
        reg = null;
        r = null;
        return context == null || context == "" || context == "undefined" ? "" : context;
    },
    formatDate: {


        format: function (date, pattern) {
            pattern = pattern || DEFAULT_PATTERN;
            return pattern.replace(SIGN_REGEXP, function ($0) {
                switch ($0.charAt(0)) {
                    case 'y': return padding(date.getFullYear(), $0.length);
                    case 'M': return padding(date.getMonth() + 1, $0.length);
                    case 'd': return padding(date.getDate(), $0.length);
                    case 'w': return date.getDay() + 1;
                    case 'h': return padding(date.getHours(), $0.length);
                    case 'm': return padding(date.getMinutes(), $0.length);
                    case 's': return padding(date.getSeconds(), $0.length);
                }
            });
        },
        dateformat: function (time) {


            let oldDate = new Date(time)
            let newDate = new Date()
            var dayNum = "";
            var getTime = (newDate.getTime() - oldDate.getTime())/1000;
  
            if(getTime < 60*5){
                dayNum = "刚刚";
            }else if(getTime >= 60*5 && getTime < 60*60){
                dayNum = parseInt(getTime / 60) + "分钟前";
            }else if(getTime >= 3600 && getTime < 3600*24){
                dayNum = parseInt(getTime / 3600) + "小时前";
            }else if(getTime >= 3600 * 24 && getTime < 3600 * 24 * 30){
                dayNum = parseInt(getTime / 3600 / 24 ) + "天前";
            }else if(getTime >= 3600 * 24 * 30 && getTime < 3600 * 24 * 30 * 12){
                dayNum = parseInt(getTime / 3600 / 24 / 30 ) + "个月前";  
            }else if(time >= 3600 * 24 * 30 * 12){
                dayNum = parseInt(getTime / 3600 / 24 / 30 / 12 ) + "年前";  
            }
  
            let year   = oldDate.getFullYear();
            let month  = oldDate.getMonth()+1;
            let day    = oldDate.getDate();
            let hour   = oldDate.getHours(); 
            let minute = oldDate.getMinutes(); 
            let second = oldDate.getSeconds(); 
            //return dayNum+" "+year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
            return dayNum;





            // pattern = pattern || DEFAULT_PATTERN;




            // return pattern.replace(SIGN_REGEXP, function ($0) {
            //     switch ($0.charAt(0)) {
            //         case 'y': return padding(date.getFullYear(), $0.length);
            //         case 'M': return padding(date.getMonth() + 1, $0.length);
            //         case 'd': return padding(date.getDate(), $0.length);
            //         case 'w': return date.getDay() + 1;
            //         case 'h': return padding(date.getHours(), $0.length);
            //         case 'm': return padding(date.getMinutes(), $0.length);
            //         case 's': return padding(date.getSeconds(), $0.length);
            //     }
            // });
        },
        parse: function (dateString, pattern) {
            var matchs1 = pattern.match(SIGN_REGEXP);
            var matchs2 = dateString.match(/(\d)+/g);
            if (matchs1.length == matchs2.length) {
                var _date = new Date(1970, 0, 1);
                for (var i = 0; i < matchs1.length; i++) {
                    var _int = parseInt(matchs2[i]);
                    var sign = matchs1[i];
                    switch (sign.charAt(0)) {
                        case 'y': _date.setFullYear(_int); break;
                        case 'M': _date.setMonth(_int - 1); break;
                        case 'd': _date.setDate(_int); break;
                        case 'h': _date.setHours(_int); break;
                        case 'm': _date.setMinutes(_int); break;
                        case 's': _date.setSeconds(_int); break;
                    }
                }
                return _date;
            }
            return null;
        }

    },
    isEmt:{
        format: function (obj) {
            if(typeof obj == "undefined" || obj == null || obj == ""){
                return true;
            }else{
                return false;
            }
        },
    }

};
