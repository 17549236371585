import axios from "./api";
//班次管理
export const GetShiftGroupPageList = (params) => {
  return axios.get(`/sa/api/ShiftGroup/GetShiftGroupPageList`, {
    params: params,
  });
};
export const ShiftGroupGetShiftGroup = (params) => {
  return axios.get(`/sa/api/ShiftGroup/GetShiftGroup`, { params: params });
};

export const AddShiftGroup = (params) => {
  return axios.post(`/sa/api/ShiftGroup/AddShiftGroup`, params);
};
export const DeleteShiftGroup = (params) => {
  return axios.delete(`/sa/api/ShiftGroup/DeleteShiftGroup`, {
    params: params,
  });
};
export const UpdateShiftGroup = (params) => {
  return axios.put(`/sa/api/ShiftGroup/UpdateShiftGroup`, params);
};
//考勤组
export const GetAttendGroupPageList = (params) => {
  return axios.get(`/sa/api/AttendGroup/GetAttendGroupPageList`, {
    params: params,
  });
};
export const GetAllAttendGroup = (params) => {
  return axios.get(`/sa/api/AttendGroup/GetAllAttendGroup`, {
    params: params,
  });
};
export const AddAttendGroup = (params) => {
  return axios.post(`/sa/api/AttendGroup/AddAttendGroup`, params);
};
export const DeleteAttendGroup = (params) => {
  return axios.delete(`/sa/api/AttendGroup/DeleteAttendGroup`, {
    params: params,
  });
};
export const UpdateAttendGroup = (params) => {
  return axios.put(`/sa/api/AttendGroup/UpdateAttendGroup`, params);
};

export const AttendGroupGetAttendGroupById = (params) => {
  return axios.get(`/sa/api/AttendGroup/GetAttendGroupById`, {
    params: params,
  });
};
export const SetAttendLock = (params) => {
  return axios.put(`/sa/api/UserSchedule/SetAttendLock?attendGroupId=` + params.attendGroupId + `&setDate=` + params.setDate + `&lockType=` + params.lockType);
  // return axios.put(`/sa/api/UserSchedule/SetAttendLock`, params);
};
export const AttenUserSchedIsLocked = (params) => {
  return axios.get(`/sa/api/UserSchedule/IsLocked`, {
    params: params,
  });
};

//获取排班
export const UserScheduleGetUserSchedule = (params) => {
  return axios.get(`/sa/api/UserSchedule/GetUserScheduleData`, {
    params: params,
  });
};
export const UserScheduleGetScheduleHeader = (params) => {
  return axios.get(`/sa/api/UserSchedule/GetScheduleHeader`, {
    params: params,
  });
};

//考勤记录
export const GetClockInTimePageList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetClockInTimePageList`, {
    params: params,
  });
};
//考勤月度统计
export const ClockInGetMonthly = (params) => {
  return axios.get(`/sa/api/ClockIn/GetMonthlySummaryPageList`, {
    params: params,
  });
};
//根据考勤id获取所属班次
export const GetShiftGroupByAttendGroupIds = (params) => {
  return axios.get(`/sa/api/ShiftGroup/GetShiftGroupByAttendGroupIds`, {
    params: params,
  });
};
//批量设置当前日期所有班次
export const SetScheduleByDate = (params) => {
  return axios.get(`/sa/api/UserSchedule/SetScheduleByDate`, {
    params: params,
  });
};
export const SetScheduleByUser = (params) => {
  return axios.get(`/sa/api/UserSchedule/SetScheduleByUser`, {
    params: params,
  });
};
//更新排班
export const SaveUserSchedule = (params) => {
  return axios.post(`/sa/api/UserSchedule/SaveUserSchedule`, params);
};

export const SetScheduleByMonth = (params) => {
  return axios.get(`/sa/api/UserSchedule/SetScheduleByMonth`, {
    params: params,
  });
};
//重算考勤结果
export const ActiveChongSuan = (params) => {
  return axios.get(`/sa/api/UserSchedule/ActiveChongSuan`, {
    params: params,
  });
};
//每日统计
export const GetDailyStatisticsPageList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetDailyStatisticsPageList`, {
    params: params,
  });
};
//每日打卡记录
export const GetUserClockInTimePageList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetClockInTimePageList`, {
    params: params,
  });
};
//出差记录
export const GetBusinessTripList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetBusinessTripList`, { params: params });
};
//加班记录
export const GetWorkOvertimeList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetWorkOvertimeList`, { params: params });
};
//请假记录
export const GetLeaveList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetLeaveList`, { params: params });
};
//小时假
export const GetHoursLeaveList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetHoursLeaveList`, { params: params });
};
//探亲假
export const GetVisitFamilyList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetVisitFamilyList`, { params: params });
};
//返回公司
export const GetReturnCompanyList = (params) => {
  return axios.get(`/sa/api/ClockIn/GetReturnCompanyList`, { params: params });
};

//区域管理相关
export const GetAreaPageList = (params) => {
  return axios.get(`/sa/api/AttendGroupArea/GetAreaPageList`, { params: params });
};
export const GetAreaById = (params) => {
  return axios.get(`/sa/api/AttendGroupArea/GetAreaById`, { params: params });
};
export const AddAttendGroupArea = (params) => {
  return axios.post(`/sa/api/AttendGroupArea/AddAttendGroupArea`, params);
};
export const DeleteAttendGroupArea = (params) => {
  return axios.delete(`/sa/api/AttendGroupArea/DeleteAttendGroupArea`, {
    params: params,
  });
};
export const UpdateAreaAttendGroup = (params) => {
  return axios.put(`/sa/api/AttendGroupArea/UpdateAttendGroup`, params);
};

//清除用户缓存
export const RemoveAllUserInfoCache = (params) => {
  return axios.delete(`/sa/api/AttendGroup/RemoveAllUserInfoCache`, { params: params });
};